<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
  >
    <rect
      width="45"
      height="45"
      rx="10"
      :fill="props.fillBackground || '#F5F6FB'"
    />
    <path
      d="M21.3255 32V23.6567H13V22.3433H21.3255V14H22.636V22.3433H31V23.6567H22.636V32H21.3255Z"
      fill="#4C00FF"
    />
  </svg>
</template>
<script setup lang="ts">

interface Props {
  fillBackground?: string | 'light-blue' | 'night-blue' | 'pink' | 'purple',
}

const props = withDefaults(defineProps<Props>(), {
  fillBackground: '#F5F6FB'
})

</script>

<style scoped>

</style>
