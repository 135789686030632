<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
  >
    <rect
      width="45"
      height="45"
      rx="10"
      :fill="props.fillBackground || 'white'"
    />
    <path
      d="M21.3255 23.6565H13V22.3432H21.3255L22.636 22.3428L31 22.3432V23.6565H22.636H21.3255Z"
      fill="#4C00FF"
    />
  </svg>
</template>

<script setup lang="ts">

interface Props {
  fillBackground?: string | 'light-blue' | 'night-blue' | 'pink' | 'purple',
}

const props = withDefaults(defineProps<Props>(), {
  fillBackground: 'white'
})

</script>

<style scoped>

</style>
