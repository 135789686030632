<template>
  <div
    ref="$el"
    class="show-more"
    :class="{[color]: color,'show-more--open': isOpen, 'show-more--toggle-only-on-mobile': toggleOnlyOnMobile, 'show-more--reverse-color': reverseColor}"
  >
    <button
      class="show-more__toggle"
      :class="{'show-more__toggle--reverse-color': reverseColor}"
      type="button"
      @click="toggle"
    >
      <slot name="toggle">
        <slot name="toggle-text" />
        <SvgDropdownMinus
          v-if="isOpen"
          :fill-background="reverseColor ? '#F5F6FB' : 'white'"
        />
        <SvgDropdownPlus
          v-else
          :fill-background="reverseColor ? 'white' : '#F5F6FB'"
        />
      </slot>
    </button>
    <div
      ref="content"
      class="show-more__content"
      :class="{'show-more__content--reverse-color': reverseColor}"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  color?: string | 'light-blue' | 'night-blue' | 'pink' | 'purple',
  initialyOpen?: boolean,
  toggleOnlyOnMobile?: boolean,
  outlined?: boolean
  reverseColor?: boolean

}

const props = withDefaults(defineProps<Props>(), {
  color: 'night-blue',
  reverseColor: false
})

const $el = ref<HTMLDivElement>()
const $content = ref<HTMLDivElement>()
const contentHeight = ref('0px')
const isOpen = ref(false)

onMounted(() => {
  setStyleVar()
  isOpen.value = props.initialyOpen
  window.addEventListener('resize', setStyleVar)
})

onUnmounted(() => {
  window.removeEventListener('resize', setStyleVar)
})

function toggle () {
  isOpen.value = !isOpen.value
}

function setStyleVar () {
  contentHeight.value = $content.value?.scrollHeight + 'px'
}
</script>

<style lang="scss" scoped>
.show-more {
  --rotate-deg: 0;
  --translate-x: 0;

  color: $night-blue;
  background-color: $white;
  border-radius: $border-radius;
  border: 1px solid #E1E1E9;

  &--reverse-color {
    color: $night-blue;
    background-color: $background;
  }

  :deep(a) {
    color: inherit;
  }

  &--toggle-only-on-mobile {
    @include media-breakpoint-up(md) {
      background: transparent;

      .show-more__toggle {
        display: none;
      }

      .show-more__content {
        max-height: unset;
      }
    }
  }

  &__toggle {
    background: $white;
    border-radius: $border-radius;
    border: 0;
    width: 100%;
    padding: 19px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    transition: color 260ms ease;
    cursor: pointer;
    position: relative;
    color: inherit;
    gap: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--reverse-color {
      background: $background;
    }

    &:focus, &:hover {
      outline: none;
    }
  }

  &__content {
    font-size: 1rem;
    padding: 0 3.5rem;
    overflow: hidden;
    max-height: 0;
    transition: 0.26s ease;
    will-change: max-height;
  }

  &--open {
    .show-more__content {
      padding: 1rem 3.5rem 3.5rem;
      max-height: v-bind(contentHeight);
    }

    .show-more__toggle {
      background: $background;

      &--reverse-color {
        background: white;
      }
    }
  }
}
</style>
